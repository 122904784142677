import React from "react";
import "./Footer.css";
import { FormControlLabel, IconButton } from "@material-ui/core";
import EmailOutline from "@material-ui/icons/MailOutline";
import PhoneOutline from "@material-ui/icons/PhoneOutlined";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__left">
        <h2 className="footer__h2">Oak Gardens</h2>
        <strong className="footer__gap">Trading since 2006</strong>
        <FormControlLabel
          control={
            <a target="_top" rel="noopener noreferrer" href="tel:07960 511 024">
              <IconButton>
                <PhoneOutline style={{ color: "whitesmoke" }} /> {/* icon */}
              </IconButton>
            </a>
          }
          label={"07960 511 024"}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <a
              target="_top"
              rel="noopener noreferrer"
              href="mailto:contact@oakgardens.uk"
            >
              <IconButton>
                <EmailOutline style={{ color: "whitesmoke" }} /> {/* icon */}
              </IconButton>
            </a>
          }
          label={"contact@oakgardens.uk"}
          labelPlacement="end"
        />
      </div>

      {/*      <div className="footer-copyright text-center py-3">
        <div>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a href="https://www.mdbootstrap.com"> MDBootstrap.com </a>
        </div>
      </div>*/}
    </footer>
  );
};

export default Footer;
