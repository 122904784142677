import * as React from "react";
import "./App.css";
import Header from "./Header";
import Home from "./Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./media-queries.css";

import Footer from "./components/Footer";
import Contact from "./Screens/Contact";
import About from "./Screens/About";
import Projects from "./Screens/Projects";

function App() {
  return (
    <Router>
      <div className="app">
        <div className="app__body">
          <Header />
          <Switch>
            <Route path="/contacta">
              <Contact />
              <Footer />
            </Route>
            <Route path="/admin">
              <Home />
              <Footer />
            </Route>
            <Route path="/about">
              <About />
              <Footer />
            </Route>
            <Route path="/projects">
              <Projects />
              <Footer />
            </Route>
            <Route path="/">
              <Home />
              <Footer />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
