import * as React from "react";
import "./Home.css";
import styled, { keyframes } from "styled-components";
import flowerOne from "./img/pics/flowers-one.jpg";
import flowerTwo from "./img/pics/flowers-two.jpg";
import flowerThree from "./img/pics/flower-three.jpg";
import flowerFour from "./img/pics/flower-four.jpg";
import flowerFive from "./img/pics/flower-five.jpg";
import flowerSix from "./img/pics/flower-six.jpg";

const fadeAndMove = keyframes`
  0%   {
    opacity: 0;
    padding-top: 150px;
  }
  60%   {
    padding-top: 0;
    opacity: 0.6;
  }
  100% {
    opacity: 1;
    padding-top: 0;
  }
`;

const ImagesContainer = styled.div`
  position: relative;
  width: 100%;
  animation: ${fadeAndMove} 2.5s infinite;
  animation-iteration-count: 1;
`;

function Home() {
  return (
    <div className="home">
      <ImagesContainer>
        <img className="home__image" src={flowerOne} alt="" />
        {/*        <div className="home__imageCaption">Wacka Wacka</div>*/}
      </ImagesContainer>
      <ImagesContainer>
        <img className="home__image" src={flowerThree} alt="" />
        {/*        <div className="home__imageCaption">Wacka Wacka</div>*/}
      </ImagesContainer>
      <ImagesContainer>
        <img className="home__image" src={flowerTwo} alt="" />
        {/*        <div className="home__imageCaption">Wacka Wacka</div>*/}
      </ImagesContainer>
      <ImagesContainer>
        <img className="home__image" src={flowerFour} alt="" />
        {/*        <div className="home__imageCaption">Wacka Wacka</div>*/}
      </ImagesContainer>
      <ImagesContainer>
        <img className="home__image" src={flowerFive} alt="" />
        {/*        <div className="home__imageCaption">Wacka Wacka</div>*/}
      </ImagesContainer>
      <ImagesContainer>
        <img className="home__image" src={flowerSix} alt="" />
        {/*        <div className="home__imageCaption">Wacka Wacka</div>*/}
      </ImagesContainer>
    </div>
  );
}

export default Home;
