import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react";
import "./styles.css";

const AxiosFunctions = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    axios
      .get("https://res.cloudinary.com/oakgardens/image/list/oakgardens.json")
      .then((res) => {
        console.log(res.data.resources);
        setImages(res.data.resources);
      });
  }, []);

  return (
    <div className="photo-hold">
      <h2>Projects</h2>
      <CloudinaryContext cloudName="oakgardens">
        {images.map((image) => {
          return (
            <div key={image.public_id}>
              <Image
                className="static-photo"
                dpr="auto"
                responsive
                width="auto"
                crop="scale"
                publicId={image.public_id}
                format="jpg"
              >
                <Transformation effect="art:eucalyptus,r_30"></Transformation>
              </Image>
            </div>
          );
        })}
      </CloudinaryContext>
    </div>
  );
};

export default AxiosFunctions;
