import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "axios";
import "./Contact.css";
import "./Flower.css";
import PhoneOutline from "@material-ui/icons/PhoneOutlined";
import EmailOutline from "@material-ui/icons/MailOutline";

const Contact = () => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    subject: "",
    description: "",
    sent: false,
    buttonText: "Submit",
    err: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const resetForm = () => {
    setInputs({
      name: "",
      email: "",
      subject: "",
      description: "",
      sent: false,
      buttonText: "Submit",
      err: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setInputs({
      ...inputs,
      buttonText: "Sending...",
    });
    const { email, name, subject, description } = inputs;
    console.log(email, name, subject, description);
    axios
      .post("sendtome", {
        //make an object to be handled from req.body on the backend.
        email,
        name,
        subject,
        //change the name to represent text on the backend.
        text: description,
      })
      .then((res) => {
        if (res.inputs.result !== "success") {
          setInputs({
            ...inputs,
            buttonText: "Failed to send",
            sent: false,
            err: "fail",
          });
          setTimeout(() => {
            resetForm();
          }, 6000);
        } else {
          setInputs({
            ...inputs,
            sent: true,
            buttonText: "Sent",
            err: "success",
          });
          setTimeout(() => {
            resetForm();
          }, 6000);
        }
      })
      .catch((err) => {
        //console.log(err.response.status)
        setInputs({
          ...inputs,
          buttonText: "Failed to send",
          err: "fail",
        });
      });
  };

  return (
    <section className="section">
      <div className="container">
        <div className="section-title">
          <h2 className="contact__title">Get in touch</h2>
        </div>
        <div className="contact__mid">
          <div className="contact__left">
            <div className="contact__inner margin-bottom">
              <div className="contact__addressContainer">
                <p>
                  Thinking of redesigning your garden or adding some garden
                  features?
                </p>{" "}
                Get in touch with the <strong>Oak Gardens</strong> for a chat.
                If you're not sure what services you need, don't worry we'll
                gladly help you through the process!{" "}
                <p>
                  <strong>Give us a call now on 07960 511 024</strong> to
                  discuss your requirements. Alternatively, you can{" "}
                  <strong>complete the contact form</strong> and we'll get in
                  touch as soon as possible.
                </p>{" "}
              </div>
            </div>
            <div className="contact__inner">
              <div className="contact__addressContainer">
                <h3>Peter O'Dowd</h3>
                <h4 className="contact__marginTwelve">Oak Gardens</h4>
                <p>105 Marlborough Road</p>
                <p>Bounds Green</p>
                <p>London</p>
                <p>N22 8NL</p>
                <FormControlLabel
                  control={
                    <a
                      target="_top"
                      rel="noopener noreferrer"
                      href="tel:07960 511 024"
                    >
                      <IconButton>
                        <PhoneOutline style={{ color: "#4c5872" }} />{" "}
                        {/* icon */}
                      </IconButton>
                    </a>
                  }
                  label={"07960 511 024"}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <a
                      target="_top"
                      rel="noopener noreferrer"
                      href="mailto:contact@oakgardens.uk"
                    >
                      <IconButton>
                        <EmailOutline style={{ color: "#4c5872" }} />{" "}
                        {/* icon */}
                      </IconButton>
                    </a>
                  }
                  label={"contact@oakgardens.uk"}
                  labelPlacement="end"
                />
              </div>
            </div>
          </div>
          <div className="contact__right">
            <div className="contact__inner">
              <form id="contact-form" method="POST">
                <FormControl fullWidth={true}>
                  <TextField
                    required
                    label="Full name"
                    variant="filled"
                    id="full-name"
                    name="name"
                    className="form-field"
                    value={inputs.name}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    required
                    label="Email"
                    id="email"
                    name="email"
                    variant="filled"
                    className="form-field"
                    value={inputs.email}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    required
                    label="Subject"
                    variant="filled"
                    id="subject"
                    name="subject"
                    className="form-field"
                    value={inputs.subject}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    required
                    label="Message"
                    variant="filled"
                    name="description"
                    multiline={true}
                    rows="10"
                    value={inputs.description}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <div className="form-submit">
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      {inputs.buttonText}
                    </Button>
                  </div>
                </FormControl>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
