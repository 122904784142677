import * as React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "./img/fixed.svg";

import {
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Container,
  Hidden,
} from "@material-ui/core";
import { Home } from "@material-ui/icons";
import SideDrawer from "./components/SideDrawer";

const useStyles = makeStyles({
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `#444`,
  },
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
    color: `#444`,
  },
  customizeToolbar: {
    minHeight: 26,
    color: `#444`,
    right: 0,
  },
  appBar: {
    backgroundColor: "transparent",
    width: "auto",
    boxShadow: "none",
    alignContent: "center",
  },
});

const navLinks = [
  { title: `Projects`, path: `/projects` },
  { title: `About Us`, path: `/about` },
  { title: `Contact`, path: `/contacta` },
];

function Header() {
  const classes = useStyles();
  /*  const handleAuthentication = () => {
    if (user) {
      auth.signOut();
    }
  };*/
  return (
    <div className="header">
      <Link to="/">
        <img src={logo} className="header__logo" alt="" />
      </Link>
      <Link to="/admin" className="header__login">
        {/*        <Link to={!user && "/login"}>
          <div onClick={handleAuthentication} className="header__option">
            <span className="header__optionLineOne">Hello {user?.email}</span>
            <span className="header__optionLineTwo">
              {user ? "Sign Out" : "Sign In"}
            </span>
          </div>
        </Link>

        <Link to="/checkout">
          <div className="header__optionBasket">
            <span className="nav-sprite-v1">
              <span className="header__optionLineTwo header__basketCount">
                {basket?.length}
              </span>
            </span>
          </div>
        </Link>*/}
      </Link>
      <div className="header__nav">
        <div className="header__navContain">
          <AppBar position="relative" className={classes.appBar}>
            <Toolbar
              className={classes.customizeToolbar}
              disableGutters
              fontWeight={400}
            >
              <Container maxWidth="md" className={classes.navbarDisplayFlex}>
                <Hidden smDown>
                  <List
                    component="nav"
                    aria-labelledby="main navigation"
                    className={classes.navDisplayFlex} // this
                  >
                    <IconButton
                      edge="start"
                      component={Link}
                      to="/"
                      color="inherit"
                      aria-label="home"
                    >
                      <Home fontSize="small" />
                    </IconButton>
                    {navLinks.map(({ title, path }) => (
                      <a href={path} key={title} className={classes.linkText}>
                        <ListItem button>
                          <ListItemText primary={title} />
                        </ListItem>
                      </a>
                    ))}
                  </List>
                </Hidden>
                <Hidden mdUp>
                  <SideDrawer navLinks={navLinks} />
                </Hidden>
              </Container>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    </div>
  );
}

export default Header;
