import React from "react";
import "./About.css";

const About = (props) => {
  return (
    <div className="about">
      <h3>About Oak Gardens</h3>

      <p>
        {" "}
        Founded in 2006 in London, Oak Gardens has created a diverse portfolio
        of landscapes and outdoor living areas.
      </p>
      <p>
        {" "}
        Whether you need residential or commercial services, we are here every
        step of the way to help you create your perfect landscape. We believe in
        pursuing nature's beauty one client at a time. Bring your personal style
        and tastes to us and we'll give you our hard work and dedication. Our
        group of professionals bring years of experience in landscape design,
        consultancy, planning and horticulture expertise. Our installations are
        well organized and completed before you know it. We pride ourselves on
        our attention to detail, unique designs and passion for excellent
        customer service. We work hand in hand with our clients to ensure that
        their landscapes and outdoor living areas are not only creative and
        aesthetically pleasing but will be functional and enjoyable for years.{" "}
      </p>
      <ul>
        <li>Landscape Design</li>
        <li>Landscape Installation</li>
        <li>Irrigation and Sprinkler Services</li>
        <li>Outdoor Construction</li>
        <li>Outdoor Lighting</li>
        <li>Water Features</li>
      </ul>
      <h3>About Peter</h3>

      <p></p>
    </div>
  );
};

export default About;
