import React from "react";
import "./Projects.css";

import AxiosFunctions from "../functions/AxiosFunctions";

const Projects = (props) => {
  return (
    <div className="Projects">
      <AxiosFunctions />
    </div>
  );
};

export default Projects;
